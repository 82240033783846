import { Button, Typography } from '@lightbeamai/design-system'
import './modalView.less'
import React from 'react'
import { useIntl } from 'react-intl'
import { Modal } from 'semantic-ui-react'

interface BasicProps {
  isOpened: boolean
  title: string | React.ReactNode
  content: string | React.ReactElement
  className?: string
  btnConfirmTestId?: string
  showControlButtons?: boolean
  disabled?: boolean
  onClose: () => void
  size?: 'large' | 'small' | 'mini' | 'tiny' | 'fullscreen'
  testId?: string
}

interface StandardControlProps extends BasicProps {
  btnConfirm?: string
  btnCancel?: string
  checkboxNotShowAgain?: string
  onConfirm: () => void
}

interface CustomControlProps extends BasicProps {
  buttons?: React.ReactElement
}

const ModalView = (props: StandardControlProps | CustomControlProps): React.ReactElement => {
  const intl = useIntl()

  let buttons: string | React.ReactElement = (
    <>
      <Button variant="secondary" className="btn cancel" onClick={props.onClose}>
        {'btnCancel' in props ? props.btnCancel : intl.formatMessage({ id: 'btn.cancel' })}
      </Button>
      {'onConfirm' in props && props.onConfirm && (
        <Button
          variant="primary"
          className="btn confirm"
          onClick={props.onConfirm}
          {...(props.btnConfirmTestId ? { 'data-test-id': props.btnConfirmTestId } : {})}
          disabled={props.disabled === undefined ? false : props.disabled}
        >
          {'btnConfirm' in props && props.btnConfirm
            ? props.btnConfirm
            : intl.formatMessage({ id: 'btn.confirm' })}
        </Button>
      )}
    </>
  )

  if ('buttons' in props && props.buttons) buttons = props.buttons

  const { showControlButtons = true, size = 'large' } = props

  const testId = props.testId || 'modal-view'

  return (
    <Modal
      open={props.isOpened}
      size={size}
      className={`modal-view ${props.className || ''}`}
      data-test-id={testId}
    >
      <Modal.Content>
        <div className="modal-view-container">
          <div className="header">
            <Typography as="span" variant="h5" color="primary">
              {props.title}
            </Typography>
            <button className="btn-text close" onClick={props.onClose}>
              &#10005;
            </button>
          </div>
          <div className="body custom-scrollbar">{props.content}</div>
          {showControlButtons && <div className="footer">{buttons}</div>}
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ModalView
